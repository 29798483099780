<template>
	<div class="visit-info-record">
		<div class="filter-content">
      <div class="visit-filter" @click="handleFilter('MyVisit')">
        <span class="filter-label txt-ellipsis" v-if="filterContent.visitlabel">{{ filterContent.visitlabel }}</span>
        <span class="filter-label txt-ellipsis" v-else-if="!filterContent.visitlabel && userInfo.user">{{ filterContent.visitlabel || userInfo.user.nickName }}</span>
        <img class="icon-img" src="@/assets/images/icon_mnew_down.png" alt="">
      </div>
      <div class="visit-filter" @click="handleFilter('statusFilter')">
        <span class="filter-label txt-ellipsis">{{ filterContent.statusLabel }}</span>
        <img class="icon-img" src="@/assets/images/icon_mnew_down.png" alt="">
      </div>
			<div class="visit-filter"@click="showTimePopup()">
				<div @click="showTimePopup">{{ filterContent.visitTime }}</div>
        <img class="icon-img" src="@/assets/images/shijianbiao.png" alt="">
      </div>
    </div>
		
		<van-pull-refresh v-model="isLoading" @refresh="pullDownRefreshFn">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="There's nothing more！"
        offset="60"
        :immediate-check="false"
        @load="pullUpLoadMoreFn"
      >
				<VisitList v-for="(visitItem, visitIndex) in visitList" :key="visitIndex" :visitDetail="visitItem" />
      </van-list>
    </van-pull-refresh>
		<van-popup v-model="filterIsShow" position="bottom" :round="true" :style="{ height: '60%' }">
      <div class="filter-popup-content">
        <div class="filter-title">{{ popupTitle }}</div>
        <div v-if="filterType === 'MyVisit'" class="project-content">
          <div v-for="(filterItem, filterIndex) in myVisitList" class="project-item" :class="{'choice-filter-active': activeVisitIndex===filterIndex}" @click.stop="changeFilter(filterItem, filterIndex)">{{ filterItem.nickName }}</div>
        </div>
				<div v-else-if="filterType === 'statusFilter'" class="project-content">
          <div v-for="(filterItem, filterIndex) in visitStatusList" class="project-item" :class="{'choice-filter-active': activeFilterIndex===filterIndex}">
						<!-- <van-checkbox shape="square" disabled icon-size="20" v-model="filterItem.value" @click.stop="handleCheck" /> -->
						<div v-if="filterItem.value" class="choice-checked" @click.stop="handleCheck(filterItem, filterIndex)"><img style="width: 23px;height: 23px;" src="@/assets/images/xuanzhong.png" alt=""></div>
						<div v-else class="choice-checke" @click.stop="handleCheck(filterItem, filterIndex)"></div>
						<span class="filter-item-name">{{ filterItem.label }}</span>
					</div>
        </div>
        <div class="operate-btn">
          <van-button type="primary" @click="resetFilter">重置</van-button>
          <!-- <van-button class="confirm-btn" type="info" @click="filterConfirm">确认</van-button> -->
        </div>
      </div>
    </van-popup>
		<van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="currentDate"
        @confirm="onConfirm"
        @cancel="timeCancel"
      />
			<div class="operate-btn">
        <van-button type="primary" @click="resetFilter">重置</van-button>
      </div>
    </van-popup>
	</div>
</template>

<script>
import { dataScopeUserListApi, arsVisitListPageApi } from '@/api/user'
import { Toast } from 'vant'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  components: {
		VisitList: () => import('./visitList')
	},
	data() {
		return {
			myVisitList: [],
			visitStatusList: [
				{ label: 'Material', value: false },
				{ label: 'Sample', value: false },
				{ label: 'Sell-In', value: false },
				{ label: 'Pre-Order', value: false },
			],
			filterIsShow: false,
			popupTitle: 'My Visit',
			activeVisitIndex: '',
			activeFilterIndex: '',
			filterType: 'MyVisit',
			filterContent: {
				visitlabel: '',
				visitlVal: '',
				statusLabel: 'Filter',
				statusVal1: false,
				statusVal2: false,
				statusVal3: false,
				statusVal4: false,
				visitTime: 'Today'
			},
			isLoading: false, // 下拉的加载状态
			loading: false, // 上拉每一页的加载状态
			finished: false,
			paginationList: {
        page: 1,
        limit: 10,
        total: 0,
      },
			visitList: [],
			showPicker: false,
			currentDate: new Date()
		}
	},
	created() {
		this.getMyVisit()
		this.pullDownRefreshFn()
	},
	computed: {
    ...mapState({
      userInfo: 'myUserInfo'
    })
  },
	activated() {
		this.getMyVisit()
		this.pullDownRefreshFn()
	},
	methods: {
		// 获取频道文字列表
    async getArticleList () {
      Toast.loading({
        message: 'loading...',
        forbidClick: true,
        duration: 0
      })
      const data = {
        ...this.paginationList,
        userIdList: this.filterContent.visitlVal ? [this.filterContent.visitlVal] : '',
        visitTime: this.filterContent.visitTime === 'Today' ? '' : this.filterContent.visitTime,
				isMateriel: this.filterContent.statusVal1 ? '1' : '',
				isSample: this.filterContent.statusVal2 ? '1' : '',
				isSellIn: this.filterContent.statusVal3 ? '1' : '',
				isPerOrder: this.filterContent.statusVal4 ? '1' : ''
      }
      arsVisitListPageApi(data).then(result => {
        Toast.clear()
        if (this.paginationList.page === 1) {
          this.visitList = []
        }
        console.log('customerAppListApi', result)
        // 没有就去请求
        this.paginationList.total = result.data.totalRow
        const storeTotal = localStorage.getItem('storeTotal') || 0
        if (Number(this.paginationList.total) != Number(storeTotal)) {
          localStorage.setItem('storeTotal', this.paginationList.total)
        }
        // 把新数组 与 原来的 数组 进行 合并
        this.visitList = [...this.visitList, ...result.data.list]
        // 把 loading 设置为false，表示 可以进行 下一次请求了
        this.paginationList.page++
        this.loading = false
        console.log('this.visitList.length', this.visitList.length)
        console.log('this.paginationList.total', this.paginationList.total)
        if (this.visitList.length === Number(this.paginationList.total)) {
          this.finished = true
        }
      }).catch(() => {
        Toast.clear()
      })
    },
		pullUpLoadMoreFn () {
      // 切换频道的时候，只有articleList 不为空，才触发 上拉加载更多的函数
      if (this.visitList.length > 0) {
        // 1. 发请求 2. 拿数据，合并
        this.getArticleList()
      }
    },
		pullDownRefreshFn () {
      console.log('pullDownRefreshFn')
      this.resetPage()
      this.getArticleList()
      this.isLoading = false
    },
		getMyVisit() {
			dataScopeUserListApi().then(res => {
				console.log('dataScopeUserListApi', res)
				this.myVisitList = res.data
			})
		},
		onConfirm(time) {
			this.filterType = 'time'
			this.filterContent.visitTime = moment(time).format('YYYY-MM-DD')
			this.pullDownRefreshFn()
      this.showPicker = false;
    },
		timeCancel() {
			this.showPicker = false
		},
		showTimePopup(params, index) {
      this.showPicker = true
    },
		handleFilter(type) {
			this.filterType = type
			if (type === 'MyVisit') {
				this.popupTitle = 'My Visit'
			} else if (type === 'statusFilter') {
				this.popupTitle = 'Filter'
			}
			this.filterIsShow = true
		},
		changeFilter(params, index) {
			console.log('changeFilter-params', params)
			console.log('changeFilter-index', index)
			if (this.filterType === 'MyVisit') {
				this.filterContent.visitlabel = params.nickName
				this.filterContent.visitlVal = params.id
				this.activeVisitIndex = index
			} else if (this.filterType === 'statusFilter') {
				this.filterContent.statusLabel = params.label
				if (params.label === 'Material') {
					this.filterContent.statusVal1 = params.value
				} else if (params.label === 'Sample') {
					this.filterContent.statusVal2 = params.value
				} else if (params.label === 'Sell-In') {
					this.filterContent.statusVal3 = params.value
				} else if (params.label === 'Pre-Order') {
					this.filterContent.statusVal4 = params.value
				}
				this.activeFilterIndex = index
			}
			console.log('this.filterContent', this.filterContent)
			this.filterConfirm()
		},
		filterConfirm() {
			this.pullDownRefreshFn()
			this.filterIsShow = false
		},
		resetPage () {
      this.paginationList =  {
        page: 1,
        limit: 10,
        total: 0
      }
      this.visitList = []
      this.finished = false
      this.loading = false
    },
		resetFilter() {
			if (this.filterType === 'MyVisit') {
				this.filterContent.visitlabel = ''
				this.filterContent.visitlVal = ''
				this.activeVisitIndex = ''
			} else if (this.filterType === 'statusFilter') {
				this.filterContent.statusLabel = 'Filter'
				this.filterContent.statusVal1 = false
				this.filterContent.statusVal2 = false
				this.filterContent.statusVal3 = false
				this.filterContent.statusVal4 = false
				this.activeFilterIndex = ''
				this.visitStatusList.forEach(item => {
					item.value = false
				})
			} else if (this.filterType === 'time') {
				this.filterContent.visitTime = 'Today'
				this.timeCancel()
			}
			this.pullDownRefreshFn()
			this.filterIsShow = false
		},
		handleCheck(params, index) {
			console.log('filterItem', params)
			if (params.label === 'Material') {
				this.filterContent.statusVal1 = !params.value
			} else if (params.label === 'Sample') {
				this.filterContent.statusVal2 = !params.value
			} else if (params.label === 'Sell-In') {
				this.filterContent.statusVal3 = !params.value
			} else if (params.label === 'Pre-Order') {
				this.filterContent.statusVal4 = !params.value
			}
			this.filterContent.statusLabel = params.label
			this.activeFilterIndex = index
			this.filterConfirm()
			params.value = !params.value
		}
	}
}
</script>

<style lang="less" scoped>
.visit-info-record {
	font-size: 12px;
	.filter-content {
    display: flex;
    justify-content: space-between;
    height: 35px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #dce5f6;
    .channel-total {
      display: flex;
      align-items: center;
      flex: 1;
      .label-name {
        width: 60px;
      }
      .label-value {
        flex: 1;
        width: 66px;
      }
    }
    .visit-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      padding-left: 10px;
      border-left: 1px solid #c8d6f1;
      &:first-child {
        border-left: none;
      }
      .filter-label {
        flex: 1;
      }
    }
    .icon-img {
      width: 20px;
      height: 20px;
    }
  }
	.filter-popup-content {
		padding: 20px 0;
		font-size: 12px;
		.filter-title {
			font-weight: bold;
			font-size: 14px;
			text-align: center;
		}
		.project-content {
			margin-top: 10px;
			height: 280px;
			overflow: auto;
			.project-item {
				display: flex;
				align-items: center;
				height: 47px;
				padding: 10px;
				border-bottom: 1px solid #dce5f6;
				.filter-item-name {
					margin-left: 2px;
				}
			}
			.choice-filter-active {
				color: #007bff;
			}
			.choice-checke {
				width: 18px;
				height: 18px;
				border: 1px solid #007aff;
			}
		}
	}
	.operate-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 20px;
			margin-bottom: 5px;
			/deep/.van-button {
				width: 120px;
				height: 40px;
				background-color: #dce5f6;
				border: 1px solid #dce5f6;
				border-radius: 8px;
				color: #000;
			}
			.confirm-btn {
				background-color: #1989fa;
				color: #fff;
				margin-left: 10px;
			}
		}
}
</style>